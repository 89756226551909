var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { ref: "main-dashborad", staticClass: "main-body" },
    [
      _c("div", { staticClass: "row topmainrow" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [
            _c("div", { staticClass: "topmainlayer card" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(_vm.$label("LBL0000323")) + " "),
                _c("span", { staticClass: "title1" }, [
                  _vm._v(_vm._s(_vm.$label("LBL0000324"))),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "title2" }, [
                  _vm._v(_vm._s(_vm.$label("LBL0000325")) + " –"),
                ]),
                _vm._v(" "),
                _c("span", { staticClass: "title3" }, [
                  _vm._v(_vm._s(_vm.$label("LBL0000326"))),
                ]),
              ]),
              _c(
                "div",
                { staticClass: "mainbtnscreen" },
                [
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "white",
                        icon: _vm.fullscreen ? "fullscreen_exit" : "fullscreen",
                      },
                      on: { click: _vm.clickFullScreen },
                    },
                    [
                      _vm.fullscreen
                        ? _c("q-tooltip", [
                            _vm._v(
                              " " + _vm._s(_vm.$label("LBL0000327")) + " "
                            ),
                          ])
                        : _c("q-tooltip", [
                            _vm._v(
                              " " + _vm._s(_vm.$label("LBL0000328")) + " "
                            ),
                          ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mainbtns" },
                [
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-5",
                        icon: "science",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage("/psi/hsl/hslManage")
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$label("LBL0000329"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-5",
                        icon: "handyman",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage("/sop/swp/safeWorkPermit")
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$label("LBL0000214"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-5",
                        icon: "school",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage("/sop/edu/result/educationResult")
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$label("LBL0000330"))),
                      ]),
                    ],
                    1
                  ),
                  _c("br"),
                  _c(
                    "q-btn",
                    {
                      staticStyle: { "margin-top": "-10px" },
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-5",
                        icon: "assessment",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage("/sop/ibm/impr")
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$label("LBL0000331"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      staticStyle: { "margin-top": "-10px" },
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-5",
                        icon: "pending_actions",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage(
                            "/sop/mim/inspection/equipmentInspection"
                          )
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$label("LBL0000332"))),
                      ]),
                    ],
                    1
                  ),
                  _c(
                    "q-btn",
                    {
                      staticStyle: { "margin-top": "-10px" },
                      attrs: {
                        flat: "",
                        round: "",
                        color: "orange-5",
                        icon: "more_time",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.goPage(
                            "/sop/mim/maintenance/equipmentMaintenance"
                          )
                        },
                      },
                    },
                    [
                      _c("q-tooltip", [
                        _vm._v(_vm._s(_vm.$label("LBL0000333"))),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "row" },
        [
          _c(
            "div",
            {
              staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-9 col-xl-9",
              staticStyle: { "margin-top": "10px" },
            },
            [
              _c("div", { staticClass: "card cardcontents maincardlayer" }, [
                _c(
                  "div",
                  {
                    staticClass:
                      "card-header non-label-icon mapheader maincardheader",
                  },
                  [
                    _c(
                      "q-chip",
                      {
                        staticClass: "chip-task",
                        attrs: {
                          square: "",
                          clickable: "",
                          outline: _vm.mapObj.activeTask !== 1,
                          color:
                            _vm.mapObj.activeTask === 1 ? "green-6" : "white",
                          "text-color":
                            _vm.mapObj.activeTask === 1 ? "white" : "black",
                          icon: "directions",
                        },
                        on: { click: () => (_vm.mapObj.activeTask = 1) },
                      },
                      [_vm._v(" " + _vm._s(_vm.$label("LBL0000214")) + " ")]
                    ),
                    _c(
                      "q-chip",
                      {
                        staticClass: "chip-task",
                        attrs: {
                          square: "",
                          clickable: "",
                          outline: _vm.mapObj.activeTask !== 2,
                          color:
                            _vm.mapObj.activeTask === 2 ? "amber-7" : "white",
                          "text-color":
                            _vm.mapObj.activeTask === 2 ? "white" : "black",
                          icon: "directions",
                        },
                        on: { click: () => (_vm.mapObj.activeTask = 2) },
                      },
                      [_vm._v(" " + _vm._s(_vm.$label("LBL0000334")) + " ")]
                    ),
                    _c(
                      "q-chip",
                      {
                        staticClass: "chip-task",
                        attrs: {
                          square: "",
                          clickable: "",
                          outline: _vm.mapObj.activeTask !== 3,
                          color:
                            _vm.mapObj.activeTask === 3
                              ? "light-blue-6"
                              : "white",
                          "text-color":
                            _vm.mapObj.activeTask === 3 ? "white" : "black",
                          icon: "directions",
                        },
                        on: { click: () => (_vm.mapObj.activeTask = 3) },
                      },
                      [_vm._v(" " + _vm._s(_vm.$label("LBL0000335")) + " ")]
                    ),
                    _c(
                      "q-chip",
                      {
                        staticClass: "chip-task",
                        attrs: {
                          square: "",
                          clickable: "",
                          outline: _vm.mapObj.activeTask !== 4,
                          color:
                            _vm.mapObj.activeTask === 4
                              ? "deep-orange-6"
                              : "white",
                          "text-color":
                            _vm.mapObj.activeTask === 4 ? "white" : "black",
                          icon: "directions",
                        },
                        on: { click: () => (_vm.mapObj.activeTask = 4) },
                      },
                      [_vm._v(" " + _vm._s(_vm.$label("LBL0000336")) + " ")]
                    ),
                    _c(
                      "q-chip",
                      {
                        staticClass: "chip-task",
                        attrs: {
                          square: "",
                          clickable: "",
                          outline: _vm.mapObj.activeTask !== 5,
                          color:
                            _vm.mapObj.activeTask === 5
                              ? "deep-purple-6"
                              : "white",
                          "text-color":
                            _vm.mapObj.activeTask === 5 ? "white" : "black",
                          icon: "directions",
                        },
                        on: { click: () => (_vm.mapObj.activeTask = 5) },
                      },
                      [_vm._v(" " + _vm._s(_vm.$label("LBL0000332")) + " ")]
                    ),
                    _c(
                      "div",
                      { staticClass: "card-more main-header-input" },
                      [
                        _c("c-datepicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mapObj.activeTask === 1,
                              expression: "mapObj.activeTask===1",
                            },
                          ],
                          attrs: {
                            range: true,
                            appendToBody: false,
                            name: "workPermitPeriod",
                          },
                          on: { datachange: _vm.getMapData },
                          model: {
                            value: _vm.mapObj.taskParam.workPermitPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.mapObj.taskParam,
                                "workPermitPeriod",
                                $$v
                              )
                            },
                            expression: "mapObj.taskParam.workPermitPeriod",
                          },
                        }),
                        _c("c-datepicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mapObj.activeTask === 2,
                              expression: "mapObj.activeTask===2",
                            },
                          ],
                          attrs: {
                            range: true,
                            appendToBody: false,
                            name: "assessRiskPeriod",
                          },
                          on: { datachange: _vm.getMapData },
                          model: {
                            value: _vm.mapObj.taskParam.assessRiskPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.mapObj.taskParam,
                                "assessRiskPeriod",
                                $$v
                              )
                            },
                            expression: "mapObj.taskParam.assessRiskPeriod",
                          },
                        }),
                        _c("c-datepicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mapObj.activeTask === 3,
                              expression: "mapObj.activeTask===3",
                            },
                          ],
                          attrs: {
                            range: true,
                            appendToBody: false,
                            name: "mocPeriod",
                          },
                          on: { datachange: _vm.getMapData },
                          model: {
                            value: _vm.mapObj.taskParam.mocPeriod,
                            callback: function ($$v) {
                              _vm.$set(_vm.mapObj.taskParam, "mocPeriod", $$v)
                            },
                            expression: "mapObj.taskParam.mocPeriod",
                          },
                        }),
                        _c("c-datepicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mapObj.activeTask === 4,
                              expression: "mapObj.activeTask===4",
                            },
                          ],
                          attrs: {
                            range: true,
                            appendToBody: false,
                            name: "accidentPeriod",
                          },
                          on: { datachange: _vm.getMapData },
                          model: {
                            value: _vm.mapObj.taskParam.accidentPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.mapObj.taskParam,
                                "accidentPeriod",
                                $$v
                              )
                            },
                            expression: "mapObj.taskParam.accidentPeriod",
                          },
                        }),
                        _c("c-datepicker", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.mapObj.activeTask === 5,
                              expression: "mapObj.activeTask===5",
                            },
                          ],
                          attrs: {
                            range: true,
                            appendToBody: false,
                            name: "equipCheckPeriod",
                          },
                          on: { datachange: _vm.getMapData },
                          model: {
                            value: _vm.mapObj.taskParam.equipCheckPeriod,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.mapObj.taskParam,
                                "equipCheckPeriod",
                                $$v
                              )
                            },
                            expression: "mapObj.taskParam.equipCheckPeriod",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "q-btn",
                      {
                        staticClass: "mainmapstart",
                        attrs: {
                          outline: !_vm.mapObj.interval.isPause,
                          color: _vm.mapObj.interval.isPause
                            ? "green-6"
                            : "white",
                          "text-color": _vm.mapObj.interval.isPause
                            ? "white"
                            : "white",
                          size: "9px",
                          label: _vm.mapObj.interval.isPause
                            ? _vm.$comm.getLangLabel("LBL0000338")
                            : _vm.$comm.getLangLabel("LBL0000339"),
                          icon: _vm.mapObj.interval.isPause
                            ? "restart_alt"
                            : "pause",
                        },
                        on: { click: _vm.pause },
                      },
                      [
                        _c("q-tooltip", [
                          _vm._v(_vm._s(_vm.$label("LBL0000337"))),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "div",
                  {
                    ref: "carousel",
                    staticClass: "card-body maincardbody",
                    staticStyle: { padding: "15px !important" },
                  },
                  [
                    _vm.mapObj.maps && _vm.mapObj.maps.length > 0
                      ? _c(
                          "q-carousel",
                          {
                            staticClass: "workPermit-carousel",
                            attrs: {
                              swipeable: "",
                              animated: "",
                              arrows: "",
                              "control-type": "regular",
                              "control-color": "grey-6",
                              infinite: "",
                            },
                            model: {
                              value: _vm.mapObj.slide,
                              callback: function ($$v) {
                                _vm.$set(_vm.mapObj, "slide", $$v)
                              },
                              expression: "mapObj.slide",
                            },
                          },
                          _vm._l(_vm.mapObj.maps, function (map, idx) {
                            return _c(
                              "q-carousel-slide",
                              {
                                key: idx,
                                attrs: { name: idx, "img-src": map.mapSrc },
                              },
                              [
                                [
                                  _c("div", {
                                    staticClass: "mapTitleDiv",
                                    domProps: {
                                      textContent: _vm._s(map.mapName),
                                    },
                                  }),
                                  _vm.mapObj.activeTask === 1
                                    ? _vm._l(map.maps, function (item, idx) {
                                        return _c(
                                          "VueDraggableResizable",
                                          {
                                            key: idx,
                                            ref: "markImage",
                                            refInFor: true,
                                            staticClass: "mainMarkImage",
                                            attrs: {
                                              resizable: false,
                                              parent: true,
                                              draggable: false,
                                              x:
                                                item.locationXcoordinate *
                                                _vm.mapRate,
                                              y:
                                                item.locationYcoordinate *
                                                _vm.mapRate,
                                              w: 40,
                                              h: 40,
                                              grid: [20, 20],
                                            },
                                          },
                                          [
                                            _c("q-icon", {
                                              staticClass: "blinking",
                                              attrs: { name: "push_pin" },
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "markImage-abbreviation-parent",
                                                style: {
                                                  width: "341px",
                                                  left: _vm.getLeft(item),
                                                  top: _vm.getTop(item),
                                                },
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "markImage-abbreviation",
                                                  },
                                                  [
                                                    _c(
                                                      "transition",
                                                      {
                                                        attrs: {
                                                          name: "mark-list",
                                                          tag: "div",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            directives: [
                                                              {
                                                                name: "show",
                                                                rawName:
                                                                  "v-show",
                                                                value: true,
                                                                expression:
                                                                  "true",
                                                              },
                                                            ],
                                                          },
                                                          _vm._l(
                                                            item.resultTypes,
                                                            function (
                                                              resultType,
                                                              idx
                                                            ) {
                                                              return _c(
                                                                "q-banner",
                                                                {
                                                                  key: idx,
                                                                  class: [
                                                                    idx % 2 ===
                                                                    1
                                                                      ? "bg-grey-3"
                                                                      : "bg-grey-1",
                                                                    "markImage-abbreviation-banner-detail",
                                                                  ],
                                                                  attrs: {
                                                                    dense: "",
                                                                  },
                                                                  scopedSlots:
                                                                    _vm._u(
                                                                      [
                                                                        {
                                                                          key: "avatar",
                                                                          fn: function () {
                                                                            return [
                                                                              _c(
                                                                                "q-icon",
                                                                                {
                                                                                  staticClass:
                                                                                    "text-red",
                                                                                  attrs:
                                                                                    {
                                                                                      name: _vm.workPermitIconName(
                                                                                        resultType
                                                                                      ),
                                                                                    },
                                                                                }
                                                                              ),
                                                                            ]
                                                                          },
                                                                          proxy: true,
                                                                        },
                                                                      ],
                                                                      null,
                                                                      true
                                                                    ),
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        resultType.sopWorkTypeName
                                                                      ) +
                                                                      " "
                                                                  ),
                                                                  _c(
                                                                    "q-badge",
                                                                    {
                                                                      attrs: {
                                                                        color:
                                                                          _vm.workPermitbadgeColor(
                                                                            resultType.swpStepName
                                                                          ),
                                                                        label:
                                                                          resultType.swpStepName,
                                                                      },
                                                                    }
                                                                  ),
                                                                  _c("q-btn", {
                                                                    staticClass:
                                                                      "q-ml-sm",
                                                                    attrs: {
                                                                      outline:
                                                                        "",
                                                                      round: "",
                                                                      dense: "",
                                                                      color:
                                                                        "red",
                                                                      size: "9px",
                                                                      label: "",
                                                                      icon: "add",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openWorkPermit(
                                                                            resultType
                                                                          )
                                                                        },
                                                                    },
                                                                  }),
                                                                  _c(
                                                                    "div",
                                                                    {
                                                                      staticClass:
                                                                        "caption-work",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " " +
                                                                          _vm._s(
                                                                            resultType.workSummary
                                                                          ) +
                                                                          " "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            }
                                                          ),
                                                          1
                                                        ),
                                                      ]
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            ),
                                          ],
                                          1
                                        )
                                      })
                                    : _vm._e(),
                                  _vm._l(map.otherMaps, function (mp, idx) {
                                    return _c(
                                      "VueDraggableResizable",
                                      {
                                        key: "other" + idx,
                                        ref: "processMark" + mp.processCd,
                                        refInFor: true,
                                        staticClass: "my-class",
                                        class:
                                          mp.processCd ===
                                          _vm.mapObj.process.processCd
                                            ? "my-active-class"
                                            : "",
                                        staticStyle: { "z-index": "1" },
                                        attrs: {
                                          parent: true,
                                          draggable: false,
                                          resizable: false,
                                          x: mp.x * _vm.mapRate,
                                          y: mp.y * _vm.mapRate,
                                          w: mp.w * _vm.mapRate,
                                          h: mp.h * _vm.mapRate,
                                          grid: [20, 20],
                                        },
                                        on: {
                                          activated: function ($event) {
                                            return _vm.onClickProcess(mp)
                                          },
                                        },
                                      },
                                      [
                                        _c("div", [
                                          _vm._v(_vm._s(mp.processName)),
                                        ]),
                                      ]
                                    )
                                  }),
                                  _vm._l(
                                    map.otherMaps.filter((i) => {
                                      return (
                                        i.tasks.filter((i) => {
                                          return (
                                            i.taskType ===
                                            String(_vm.mapObj.activeTask)
                                          )
                                        }).length > 0
                                      )
                                    }),
                                    function (mp, idx) {
                                      return _c(
                                        "div",
                                        {
                                          key: "other-tran" + idx,
                                          staticClass: "task-parent",
                                          style: _vm.setStyleTaskArea(mp),
                                          on: {
                                            mouseover: () => {
                                              mp.isHover = true
                                            },
                                            mouseleave: () => {
                                              mp.isHover = false
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.activeTask ===
                                                      2 &&
                                                    mp.tasks.filter((i) => {
                                                      return (
                                                        i.taskType ===
                                                        String(
                                                          _vm.mapObj.activeTask
                                                        )
                                                      )
                                                    }).length > 0,
                                                  expression:
                                                    "mapObj.activeTask===2 \n                      && mp.tasks.filter(i => {\n                          return i.taskType === String(mapObj.activeTask)\n                        }).length > 0",
                                                },
                                              ],
                                              staticClass:
                                                "rounded-borders task-detail",
                                              staticStyle: {
                                                "border-top":
                                                  "3px solid #ffb300",
                                              },
                                            },
                                            _vm._l(
                                              mp.tasks
                                                .filter((i) => {
                                                  return (
                                                    i.taskType ===
                                                    String(
                                                      _vm.mapObj.activeTask
                                                    )
                                                  )
                                                })
                                                .slice(0, 1),
                                              function (task, _idx) {
                                                return _c(
                                                  "q-banner",
                                                  {
                                                    key: _idx,
                                                    attrs: { dense: "" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mainmapbannerTitle",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(task.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "caption-work2",
                                                        staticStyle: {
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000340"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-amber-7",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.actionCnt
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000341"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-amber-7",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.riskRegisterCnt
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000342"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-amber-7",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.notActionImprCnt
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.activeTask ===
                                                      3 &&
                                                    mp.tasks.filter((i) => {
                                                      return (
                                                        i.taskType ===
                                                        String(
                                                          _vm.mapObj.activeTask
                                                        )
                                                      )
                                                    }).length > 0,
                                                  expression:
                                                    "mapObj.activeTask===3 \n                      && mp.tasks.filter(i => {\n                          return i.taskType === String(mapObj.activeTask)\n                        }).length > 0",
                                                },
                                              ],
                                              staticClass:
                                                "rounded-borders task-detail",
                                              staticStyle: {
                                                "border-top":
                                                  "3px solid #03a9f4",
                                              },
                                            },
                                            _vm._l(
                                              mp.tasks
                                                .filter((i) => {
                                                  return (
                                                    i.taskType ===
                                                    String(
                                                      _vm.mapObj.activeTask
                                                    )
                                                  )
                                                })
                                                .slice(0, 1),
                                              function (task, _idx) {
                                                return _c(
                                                  "q-banner",
                                                  {
                                                    key: _idx,
                                                    attrs: { dense: "" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mainmapbannerTitle",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(task.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "caption-work2",
                                                        staticStyle: {
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000343"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-blue",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.psiCnt
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000344"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-light-blue-6",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.assessCnt
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000345"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-light-blue-6",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.preStartCnt
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000346"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-light-blue-6",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.punchCnt
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.activeTask ===
                                                      4 &&
                                                    mp.tasks.filter((i) => {
                                                      return (
                                                        i.taskType ===
                                                        String(
                                                          _vm.mapObj.activeTask
                                                        )
                                                      )
                                                    }).length > 0,
                                                  expression:
                                                    "mapObj.activeTask===4\n                      && mp.tasks.filter(i => {\n                          return i.taskType === String(mapObj.activeTask)\n                        }).length > 0",
                                                },
                                              ],
                                              staticClass:
                                                "rounded-borders task-detail",
                                              staticStyle: {
                                                "border-top":
                                                  "3px solid #ff5722",
                                              },
                                            },
                                            _vm._l(
                                              mp.tasks
                                                .filter((i) => {
                                                  return (
                                                    i.taskType ===
                                                    String(
                                                      _vm.mapObj.activeTask
                                                    )
                                                  )
                                                })
                                                .slice(0, 1),
                                              function (task, _idx) {
                                                return _c(
                                                  "q-banner",
                                                  {
                                                    key: _idx,
                                                    attrs: { dense: "" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mainmapbannerTitle",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(task.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "caption-work2",
                                                        staticStyle: {
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000347"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-deep-orange-6",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.accidentKindName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000348"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-deep-orange-6",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.accidentGradeName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000349"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-deep-orange-6",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.occurrenceModeName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.activeTask ===
                                                      5 &&
                                                    mp.tasks.filter((i) => {
                                                      return (
                                                        i.taskType ===
                                                        String(
                                                          _vm.mapObj.activeTask
                                                        )
                                                      )
                                                    }).length > 0,
                                                  expression:
                                                    "mapObj.activeTask===5\n                      && mp.tasks.filter(i => {\n                          return i.taskType === String(mapObj.activeTask)\n                        }).length > 0",
                                                },
                                              ],
                                              staticClass:
                                                "rounded-borders task-detail",
                                              staticStyle: {
                                                "border-top":
                                                  "3px solid #673ab7",
                                              },
                                            },
                                            _vm._l(
                                              mp.tasks
                                                .filter((i) => {
                                                  return (
                                                    i.taskType ===
                                                    String(
                                                      _vm.mapObj.activeTask
                                                    )
                                                  )
                                                })
                                                .slice(0, 1),
                                              function (task, _idx) {
                                                return _c(
                                                  "q-banner",
                                                  {
                                                    key: _idx,
                                                    attrs: { dense: "" },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "mainmapbannerTitle",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(task.name)
                                                        ),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "caption-work2",
                                                        staticStyle: {
                                                          "text-align": "left",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000350"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-deep-purple-4",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.equipmentCheckKindName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000351"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-deep-purple-4",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.checkDate
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c("br"),
                                                        _vm._v(
                                                          " " +
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000352"
                                                              )
                                                            ) +
                                                            ": "
                                                        ),
                                                        _c(
                                                          "font",
                                                          {
                                                            staticClass:
                                                              "text-deep-purple-4",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                task.checkUserName
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              }
                                            ),
                                            1
                                          ),
                                        ]
                                      )
                                    }
                                  ),
                                ],
                              ],
                              2
                            )
                          }),
                          1
                        )
                      : _c("el-empty", {
                          attrs: {
                            "image-size": 435,
                            description: _vm.$comm.getLangMessage("MSG0000137"),
                          },
                        }),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _c(
            "transition",
            {
              attrs: {
                appear: "",
                "enter-active-class": "animated fadeIn",
                "leave-active-class": "animated backOutRight",
              },
            },
            [
              _c(
                "div",
                {
                  ref: "taskDetailArea",
                  staticClass:
                    "col-xs-12 col-sm-12 col-md-12 col-lg-3 col-xl-3",
                  class: _vm.isTaskDetailShow ? "open-task" : "",
                  style: _vm.isTaskDetailShow
                    ? "padding:10px !important;"
                    : "margin-top:15px;",
                  attrs: { tabindex: "0" },
                },
                [
                  _c("div", { staticClass: "row" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                        style: !_vm.isTaskDetailShow
                          ? "padding-left:10px !important;padding-bottom:10px !important;"
                          : "",
                      },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm main-map-card",
                            attrs: {
                              topClass: "noborderCard",
                              title:
                                (_vm.mapObj.process.processName
                                  ? "[" + _vm.mapObj.process.processName + "]"
                                  : "") + this.$comm.getLangLabel("LBL0000353"),
                              titleClass: _vm.isTaskDetailShow
                                ? "orange-5"
                                : "white",
                              height: _vm.isTaskDetailShow ? "310px" : "310px",
                              isCardIcon: false,
                            },
                          },
                          [
                            _c(
                              "template",
                              { slot: "card-button" },
                              [
                                _c(
                                  "q-btn-group",
                                  { attrs: { outline: "" } },
                                  [
                                    _c("c-btn", {
                                      attrs: {
                                        icon: !_vm.isTaskDetailShow
                                          ? "arrow_back_ios_new"
                                          : "arrow_forward_ios",
                                      },
                                      on: { btnClicked: _vm.openTaskDetail },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.isTaskDetailShow,
                                      expression: "!isTaskDetailShow",
                                    },
                                  ],
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                },
                                [
                                  _c(
                                    "q-tabs",
                                    {
                                      staticClass:
                                        "text-white mainDashboardTab",
                                      attrs: {
                                        dense: "",
                                        align: "justify",
                                        "active-color": "deep-white",
                                        "indicator-color": "deep-white",
                                        "inline-label": "",
                                      },
                                      model: {
                                        value: _vm.maintab1,
                                        callback: function ($$v) {
                                          _vm.maintab1 = $$v
                                        },
                                        expression: "maintab1",
                                      },
                                    },
                                    [
                                      _c("q-tab", {
                                        attrs: {
                                          name: "dept",
                                          label:
                                            _vm.$comm.getLangLabel(
                                              "LBL0000354"
                                            ),
                                        },
                                      }),
                                      _c("q-tab", {
                                        attrs: {
                                          name: "equip",
                                          label:
                                            _vm.$comm.getLangLabel("LBLEQUIP"),
                                        },
                                      }),
                                      _c("q-tab", {
                                        attrs: {
                                          name: "material",
                                          label:
                                            _vm.$comm.getLangLabel(
                                              "LBL0000356"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("q-separator"),
                                  _c(
                                    "q-tab-panels",
                                    {
                                      staticClass: "maintabbody",
                                      attrs: { animated: "" },
                                      model: {
                                        value: _vm.maintab1,
                                        callback: function ($$v) {
                                          _vm.maintab1 = $$v
                                        },
                                        expression: "maintab1",
                                      },
                                    },
                                    [
                                      _c(
                                        "q-tab-panel",
                                        { attrs: { name: "dept" } },
                                        [
                                          _vm._l(
                                            _vm.mapObj.process.depts,
                                            function (dept, deptIdx) {
                                              return _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value: deptIdx < 6,
                                                      expression: "deptIdx < 6",
                                                    },
                                                  ],
                                                  key: deptIdx,
                                                  staticClass: "maintabTable",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: deptIdx < 5,
                                                          expression:
                                                            "deptIdx < 5",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTd7",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(dept.deptName)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: deptIdx < 5,
                                                          expression:
                                                            "deptIdx < 5",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTd3",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          dept.workerCount
                                                        ) +
                                                          _vm._s(
                                                            _vm.$label(
                                                              "LBL0000357"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.mapObj.process
                                                              .depts.length >
                                                              5 && deptIdx == 5,
                                                          expression:
                                                            "mapObj.process.depts.length > 5 && deptIdx == 5",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTdgreen",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000358"
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.mapObj.process
                                                              .depts.length - 5
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$message(
                                                              "MSG0000123"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.process.depts
                                                      .length === 0,
                                                  expression:
                                                    "mapObj.process.depts.length === 0",
                                                },
                                              ],
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTdgreen",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$message("MSG0000124")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "q-tab-panel",
                                        { attrs: { name: "equip" } },
                                        [
                                          _vm._l(
                                            _vm.mapObj.process.equips,
                                            function (equip, equipIdx) {
                                              return _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        equipIdx < 6 &&
                                                        !_vm.isTaskDetailShow,
                                                      expression:
                                                        "equipIdx < 6 && !isTaskDetailShow",
                                                    },
                                                  ],
                                                  key: equipIdx,
                                                  staticClass: "maintabTable",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: equipIdx < 5,
                                                          expression:
                                                            "equipIdx < 5",
                                                        },
                                                      ],
                                                      staticClass: "maintabTd",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          equip.equipmentName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.mapObj.process
                                                              .equips.length >
                                                              5 &&
                                                            equipIdx == 5,
                                                          expression:
                                                            "mapObj.process.equips.length > 5 && equipIdx == 5",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTdgreen",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000358"
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.mapObj.process
                                                              .equips.length - 5
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$message(
                                                              "MSG0000125"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.mapObj.process
                                                              .equips.length ==
                                                            0,
                                                          expression:
                                                            "mapObj.process.equips.length == 0",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTdgreen",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$message(
                                                            "MSG0000126"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.process.equips
                                                      .length === 0 &&
                                                    !_vm.isTaskDetailShow,
                                                  expression:
                                                    "mapObj.process.equips.length === 0 && !isTaskDetailShow",
                                                },
                                              ],
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTdgreen",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$message("MSG0000126")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "q-tab-panel",
                                        { attrs: { name: "material" } },
                                        [
                                          _vm._l(
                                            _vm.mapObj.process.hazardChems,
                                            function (hazard, hazardIdx) {
                                              return _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        hazardIdx < 6 &&
                                                        !_vm.isTaskDetailShow,
                                                      expression:
                                                        "hazardIdx < 6 && !isTaskDetailShow",
                                                    },
                                                  ],
                                                  key: hazardIdx,
                                                  staticClass: "maintabTable",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: hazardIdx < 5,
                                                          expression:
                                                            "hazardIdx < 5",
                                                        },
                                                      ],
                                                      staticClass: "maintabTd",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          hazard.materialName
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.mapObj.process
                                                              .hazardChems
                                                              .length > 5 &&
                                                            hazardIdx == 5,
                                                          expression:
                                                            "mapObj.process.hazardChems.length > 5 && hazardIdx == 5",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTdgreen",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000358"
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.mapObj.process
                                                              .hazardChems
                                                              .length - 5
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$message(
                                                              "MSG0000127"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.process
                                                      .hazardChems.length ===
                                                      0 &&
                                                    !_vm.isTaskDetailShow,
                                                  expression:
                                                    "mapObj.process.hazardChems.length === 0 && !isTaskDetailShow",
                                                },
                                              ],
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTdgreen",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$message("MSG0000128")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isTaskDetailShow,
                                      expression: "isTaskDetailShow",
                                    },
                                  ],
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3",
                                  staticStyle: {
                                    "padding-right": "5px !important",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "maintabTable maintabTableTop",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "maintabTr maintabTd7 text-center",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$label("LBL0000359"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "maintabTr maintabTd3 text-center",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$label("LBL0000360"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "maintablebody" },
                                    [
                                      _vm._l(
                                        _vm.mapObj.process.depts,
                                        function (dept, deptIdx) {
                                          return _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.process.depts
                                                      .length > 0,
                                                  expression:
                                                    "mapObj.process.depts.length > 0",
                                                },
                                              ],
                                              key: deptIdx,
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTd7 padding-left10",
                                                },
                                                [_vm._v(_vm._s(dept.deptName))]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTd3 text-center",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(dept.workerCount) +
                                                      _vm._s(
                                                        _vm.$label("LBL0000357")
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.mapObj.process.depts
                                                  .length === 0,
                                              expression:
                                                "mapObj.process.depts.length === 0",
                                            },
                                          ],
                                          staticClass: "maintabTabl",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "maintabTd maintabTdgreen",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$message("MSG0000124")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isTaskDetailShow,
                                      expression: "isTaskDetailShow",
                                    },
                                  ],
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 maintableFulle",
                                  staticStyle: {
                                    "padding-right": "5px !important",
                                  },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "maintabTable maintabTableTop",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "maintabTr maintabTd6 text-center",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$label("LBL0000361"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "maintabTr maintabTd4 text-center",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$label("LBL0000362"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "maintablebody" },
                                    [
                                      _vm._l(
                                        _vm.mapObj.process.equips,
                                        function (equip, equipIdx) {
                                          return _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.process.equips
                                                      .length > 0,
                                                  expression:
                                                    "mapObj.process.equips.length > 0",
                                                },
                                              ],
                                              key: equipIdx,
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTd6 link-line padding-left10",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.linkEquipClick(
                                                        {
                                                          equipmentCd:
                                                            equip.equipmentCd,
                                                          plantCd:
                                                            equip.plantCd,
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(equip.equipmentName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTd4",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      equip.equipmentTypeName
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.mapObj.process.equips
                                                  .length === 0,
                                              expression:
                                                "mapObj.process.equips.length === 0",
                                            },
                                          ],
                                          staticClass: "maintabTable",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "maintabTd maintabTdgreen",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$message("MSG0000126")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.isTaskDetailShow,
                                      expression: "isTaskDetailShow",
                                    },
                                  ],
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-5 col-lg-5 col-xl-5 maintableFulle",
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "maintabTable maintabTableTop",
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "maintabTr maintabTd6 text-center",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$label("LBL0000363"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "maintabTr maintabTd2 text-center",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$label("LBL0000364"))
                                          ),
                                        ]
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "maintabTr maintabTd2 text-center",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$label("LBL0000365"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "maintablebody" },
                                    [
                                      _vm._l(
                                        _vm.mapObj.process.hazardChems,
                                        function (hazard, hazardIdx) {
                                          return _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.process
                                                      .hazardChems.length > 0,
                                                  expression:
                                                    "mapObj.process.hazardChems.length > 0",
                                                },
                                              ],
                                              key: hazardIdx,
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTd6 link-line padding-left10",
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.linkChemClick(
                                                        {
                                                          mdmChemMaterialId:
                                                            hazard.mdmChemMaterialId,
                                                          plantCd:
                                                            hazard.plantCd,
                                                        },
                                                        "chemPsm"
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(hazard.materialName)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTd2 text-right",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(hazard.dailyVolume)
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTd2 text-center",
                                                },
                                                [
                                                  _c("q-btn", {
                                                    attrs: {
                                                      round: "",
                                                      unelevated: "",
                                                      size: "6px",
                                                      color: "amber",
                                                      icon: "search",
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.linkChemClick(
                                                          {
                                                            mdmChemMaterialId:
                                                              hazard.mdmChemMaterialId,
                                                            plantCd:
                                                              hazard.plantCd,
                                                          },
                                                          "chemEtc"
                                                        )
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          )
                                        }
                                      ),
                                      _c(
                                        "div",
                                        {
                                          directives: [
                                            {
                                              name: "show",
                                              rawName: "v-show",
                                              value:
                                                _vm.mapObj.process.hazardChems
                                                  .length === 0,
                                              expression:
                                                "mapObj.process.hazardChems.length === 0",
                                            },
                                          ],
                                          staticClass: "maintabTable",
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "maintabTd maintabTdgreen",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.$message("MSG0000128")
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    2
                                  ),
                                ]
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 q-mt-sm",
                        style: !_vm.isTaskDetailShow
                          ? "padding-left:10px !important;padding-bottom:10px !important;"
                          : "",
                      },
                      [
                        _c(
                          "c-card",
                          {
                            staticClass: "cardClassDetailForm main-map-card",
                            attrs: {
                              topClass: "noborderCard",
                              title:
                                (_vm.mapObj.process.processName
                                  ? "[" + _vm.mapObj.process.processName + "]"
                                  : "") + _vm.$comm.getLangLabel("LBL0000366"),
                              titleClass: _vm.isTaskDetailShow
                                ? "orange-5"
                                : "white",
                              height: _vm.isTaskDetailShow ? "485px" : "350px",
                              isCardIcon: false,
                            },
                          },
                          [
                            _c("template", { slot: "card-detail" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12",
                                },
                                [
                                  _c(
                                    "q-tabs",
                                    {
                                      class: _vm.isTaskDetailShow
                                        ? "text-white mainDashboardTab mainDashboardTabS"
                                        : "text-white mainDashboardTab",
                                      attrs: {
                                        dense: "",
                                        align: "justify",
                                        "active-color": _vm.isTaskDetailShow
                                          ? "white"
                                          : "white",
                                        "indicator-color": _vm.isTaskDetailShow
                                          ? "white"
                                          : "white",
                                        "inline-label": "",
                                      },
                                      on: { input: _vm.tabClick },
                                      model: {
                                        value: _vm.maintab2,
                                        callback: function ($$v) {
                                          _vm.maintab2 = $$v
                                        },
                                        expression: "maintab2",
                                      },
                                    },
                                    [
                                      _c("q-tab", {
                                        attrs: {
                                          name: "risk",
                                          label:
                                            _vm.$comm.getLangLabel(
                                              "LBL0000334"
                                            ),
                                        },
                                      }),
                                      _c("q-tab", {
                                        attrs: {
                                          name: "moc",
                                          label:
                                            _vm.$comm.getLangLabel(
                                              "LBL0000335"
                                            ),
                                        },
                                      }),
                                      _c("q-tab", {
                                        attrs: {
                                          name: "accident",
                                          label:
                                            _vm.$comm.getLangLabel(
                                              "LBL0000336"
                                            ),
                                        },
                                      }),
                                      _c("q-tab", {
                                        attrs: {
                                          name: "equipCheck",
                                          label:
                                            _vm.$comm.getLangLabel(
                                              "LBL0000332"
                                            ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c("q-separator"),
                                  _c(
                                    "q-tab-panels",
                                    {
                                      staticClass: "maintabbody",
                                      attrs: { animated: "" },
                                      model: {
                                        value: _vm.maintab2,
                                        callback: function ($$v) {
                                          _vm.maintab2 = $$v
                                        },
                                        expression: "maintab2",
                                      },
                                    },
                                    [
                                      _c(
                                        "q-tab-panel",
                                        { attrs: { name: "risk" } },
                                        [
                                          _vm._l(
                                            _vm.mapObj.taskDetail.assessRisks,
                                            function (
                                              assessRisk,
                                              assessRiskIdx
                                            ) {
                                              return _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        assessRiskIdx < 7 &&
                                                        !_vm.isTaskDetailShow,
                                                      expression:
                                                        "assessRiskIdx < 7 && !isTaskDetailShow",
                                                    },
                                                  ],
                                                  key: assessRiskIdx,
                                                  staticClass: "maintabTable",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            assessRiskIdx < 6,
                                                          expression:
                                                            "assessRiskIdx < 6",
                                                        },
                                                      ],
                                                      staticClass: "maintabTd",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(assessRisk.name)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.mapObj
                                                              .taskDetail
                                                              .assessRisks
                                                              .length > 6 &&
                                                            assessRiskIdx == 6,
                                                          expression:
                                                            "mapObj.taskDetail.assessRisks.length > 6 && assessRiskIdx == 6",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTdgreen",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000358"
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.mapObj
                                                              .taskDetail
                                                              .assessRisks
                                                              .length - 6
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$message(
                                                              "MSG0000129"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.taskDetail
                                                      .assessRisks.length ===
                                                      0 &&
                                                    !_vm.isTaskDetailShow,
                                                  expression:
                                                    "mapObj.taskDetail.assessRisks.length === 0 && !isTaskDetailShow",
                                                },
                                              ],
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTdgreen",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$message("MSG0000130")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.isTaskDetailShow,
                                                  expression:
                                                    "isTaskDetailShow",
                                                },
                                              ],
                                              staticClass: "row",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { class: _vm.colType3 },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "maintabTable maintabTableTop",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd6 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000367"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000368"
                                                              )
                                                            )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000371"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000369"
                                                              )
                                                            )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000371"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000370"
                                                              )
                                                            )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000372"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "maintablebody maintablebody2",
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.mapObj.taskDetail
                                                          .assessRisks,
                                                        function (
                                                          assess,
                                                          assessIdx
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.mapObj
                                                                      .taskDetail
                                                                      .assessRisks
                                                                      .length >
                                                                    0,
                                                                  expression:
                                                                    "mapObj.taskDetail.assessRisks.length > 0",
                                                                },
                                                              ],
                                                              key: assessIdx,
                                                              staticClass:
                                                                "maintabTable",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd6 link-line padding-left10",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.linkAssessClick(
                                                                          {
                                                                            id: assess.id,
                                                                            docuType:
                                                                              assess.docuType,
                                                                            docuStep:
                                                                              assess.docuStep,
                                                                            ramTechniqueCd:
                                                                              assess.ramTechniqueCd,
                                                                            vendorFlag:
                                                                              assess.vendorFlag,
                                                                          }
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.actionCnt
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.riskRegisterCnt
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.notActionImprCnt
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.mapObj
                                                                  .taskDetail
                                                                  .assessRisks
                                                                  .length === 0,
                                                              expression:
                                                                "mapObj.taskDetail.assessRisks.length === 0",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "maintabTable",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTdgreen",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$message(
                                                                    "MSG0000130"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { class: _vm.colType3 },
                                                [
                                                  _c("apexchart", {
                                                    ref: "assessRiskChart",
                                                    attrs: {
                                                      height: "400px",
                                                      type: "bar",
                                                      width:
                                                        _vm.assessRiskChart
                                                          .chartWidth,
                                                      options:
                                                        _vm.assessRiskChart
                                                          .chartOptions,
                                                      series:
                                                        _vm.assessRiskChart
                                                          .series,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "q-tab-panel",
                                        { attrs: { name: "moc" } },
                                        [
                                          _vm._l(
                                            _vm.mapObj.taskDetail.mocs,
                                            function (moc, mocIdx) {
                                              return _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        mocIdx < 7 &&
                                                        !_vm.isTaskDetailShow,
                                                      expression:
                                                        "mocIdx < 7 && !isTaskDetailShow",
                                                    },
                                                  ],
                                                  key: mocIdx,
                                                  staticClass: "maintabTable",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value: mocIdx < 6,
                                                          expression:
                                                            "mocIdx < 6",
                                                        },
                                                      ],
                                                      staticClass: "maintabTd",
                                                    },
                                                    [_vm._v(_vm._s(moc.name))]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.mapObj
                                                              .taskDetail.mocs
                                                              .length > 6 &&
                                                            mocIdx == 6,
                                                          expression:
                                                            "mapObj.taskDetail.mocs.length > 6 && mocIdx == 6",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTdgreen",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000358"
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.mapObj
                                                              .taskDetail.mocs
                                                              .length - 6
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$message(
                                                              "MSG0000132"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.taskDetail.mocs
                                                      .length === 0 &&
                                                    !_vm.isTaskDetailShow,
                                                  expression:
                                                    "mapObj.taskDetail.mocs.length === 0 && !isTaskDetailShow",
                                                },
                                              ],
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTdgreen",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$message("MSG0000131")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.isTaskDetailShow,
                                                  expression:
                                                    "isTaskDetailShow",
                                                },
                                              ],
                                              staticClass: "row",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { class: _vm.colType3 },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "maintabTable maintabTableTop",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd4 text-center padding-left10",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000373"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            "PSI" +
                                                              _vm._s(
                                                                _vm.$label(
                                                                  "LBL0000374"
                                                                )
                                                              )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000334"
                                                              )
                                                            )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000372"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000375"
                                                              )
                                                            )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000376"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000372"
                                                              )
                                                            )
                                                          ),
                                                          _c("br"),
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000376"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "maintablebody maintablebody2",
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.mapObj.taskDetail
                                                          .mocs,
                                                        function (
                                                          assess,
                                                          assessIdx
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.mapObj
                                                                      .taskDetail
                                                                      .mocs
                                                                      .length >
                                                                    0,
                                                                  expression:
                                                                    "mapObj.taskDetail.mocs.length > 0",
                                                                },
                                                              ],
                                                              key: assessIdx,
                                                              staticClass:
                                                                "maintabTable",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd4 link-line",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.linkMocClick(
                                                                          {
                                                                            id: assess.id,
                                                                            docuType:
                                                                              assess.docuType,
                                                                            docuStep:
                                                                              assess.docuStep,
                                                                          }
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.psiCnt
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.assessCnt
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.preStartCnt
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-right",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.punchCnt
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.mapObj
                                                                  .taskDetail
                                                                  .mocs
                                                                  .length === 0,
                                                              expression:
                                                                "mapObj.taskDetail.mocs.length === 0",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "maintabTable",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTdgreen",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$message(
                                                                    "MSG0000131"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { class: _vm.colType3 },
                                                [
                                                  _c("apexchart", {
                                                    ref: "mocChart",
                                                    attrs: {
                                                      height: "400px",
                                                      type: "bar",
                                                      width:
                                                        _vm.mocChart.chartWidth,
                                                      options:
                                                        _vm.mocChart
                                                          .chartOptions,
                                                      series:
                                                        _vm.mocChart.series,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "q-tab-panel",
                                        { attrs: { name: "accident" } },
                                        [
                                          _vm._l(
                                            _vm.mapObj.taskDetail.accidents,
                                            function (accident, accidentIdx) {
                                              return _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        accidentIdx < 7 &&
                                                        !_vm.isTaskDetailShow,
                                                      expression:
                                                        "accidentIdx < 7 && !isTaskDetailShow",
                                                    },
                                                  ],
                                                  key: accidentIdx,
                                                  staticClass: "maintabTable",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            accidentIdx < 6,
                                                          expression:
                                                            "accidentIdx < 6",
                                                        },
                                                      ],
                                                      staticClass: "maintabTd",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(accident.name)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.mapObj
                                                              .taskDetail
                                                              .accidents
                                                              .length > 6 &&
                                                            accidentIdx == 6,
                                                          expression:
                                                            "mapObj.taskDetail.accidents.length > 6 && accidentIdx == 6",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTdgreen",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000358"
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.mapObj
                                                              .taskDetail
                                                              .accidents
                                                              .length - 6
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$message(
                                                              "MSG0000133"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.taskDetail
                                                      .accidents.length === 0 &&
                                                    !_vm.isTaskDetailShow,
                                                  expression:
                                                    "mapObj.taskDetail.accidents.length === 0 && !isTaskDetailShow",
                                                },
                                              ],
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTdgreen",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$message("MSG0000134")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.isTaskDetailShow,
                                                  expression:
                                                    "isTaskDetailShow",
                                                },
                                              ],
                                              staticClass: "row",
                                            },
                                            [
                                              _c(
                                                "div",
                                                { class: _vm.colType3 },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "maintabTable maintabTableTop",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd6 text-center padding-left10",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000336"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000347"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000348"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTr maintabTd2 text-center",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$label(
                                                                "LBL0000349"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "maintablebody maintablebody2",
                                                    },
                                                    [
                                                      _vm._l(
                                                        _vm.mapObj.taskDetail
                                                          .accidents,
                                                        function (
                                                          assess,
                                                          assessIdx
                                                        ) {
                                                          return _c(
                                                            "div",
                                                            {
                                                              directives: [
                                                                {
                                                                  name: "show",
                                                                  rawName:
                                                                    "v-show",
                                                                  value:
                                                                    _vm.mapObj
                                                                      .taskDetail
                                                                      .accidents
                                                                      .length >
                                                                    0,
                                                                  expression:
                                                                    "mapObj.taskDetail.accidents.length > 0",
                                                                },
                                                              ],
                                                              key: assessIdx,
                                                              staticClass:
                                                                "maintabTable",
                                                            },
                                                            [
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd6 link-line",
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.linkAccidentClick(
                                                                          {
                                                                            id: assess.id,
                                                                            docuType:
                                                                              assess.docuType,
                                                                            docuStep:
                                                                              assess.docuStep,
                                                                          }
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.name
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.accidentKindName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.accidentGradeName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "maintabTd maintabTd2 text-center",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      assess.occurrenceModeName
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          )
                                                        }
                                                      ),
                                                      _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.mapObj
                                                                  .taskDetail
                                                                  .accidents
                                                                  .length === 0,
                                                              expression:
                                                                "mapObj.taskDetail.accidents.length === 0",
                                                            },
                                                          ],
                                                          staticClass:
                                                            "maintabTable",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTdgreen",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.$message(
                                                                    "MSG0000134"
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    2
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                { class: _vm.colType3 },
                                                [
                                                  _c("apexchart", {
                                                    ref: "kindOcuurChart2",
                                                    attrs: {
                                                      height: "350px",
                                                      type: "polarArea",
                                                      width:
                                                        _vm.kindOcuurChart2
                                                          .chartWidth,
                                                      options:
                                                        _vm.kindOcuurChart2
                                                          .chartOptions,
                                                      series:
                                                        _vm.kindOcuurChart2
                                                          .series,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                      _c(
                                        "q-tab-panel",
                                        { attrs: { name: "equipCheck" } },
                                        [
                                          _vm._l(
                                            _vm.mapObj.taskDetail.equipChecks,
                                            function (
                                              equipCheck,
                                              equipCheckIdx
                                            ) {
                                              return _c(
                                                "div",
                                                {
                                                  directives: [
                                                    {
                                                      name: "show",
                                                      rawName: "v-show",
                                                      value:
                                                        equipCheckIdx < 7 &&
                                                        !_vm.isTaskDetailShow,
                                                      expression:
                                                        "equipCheckIdx < 7 && !isTaskDetailShow",
                                                    },
                                                  ],
                                                  key: equipCheckIdx,
                                                  staticClass: "maintabTable",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            equipCheckIdx < 6,
                                                          expression:
                                                            "equipCheckIdx < 6",
                                                        },
                                                      ],
                                                      staticClass: "maintabTd",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(equipCheck.name)
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.mapObj
                                                              .taskDetail
                                                              .equipChecks
                                                              .length > 6 &&
                                                            equipCheckIdx == 6,
                                                          expression:
                                                            "mapObj.taskDetail.equipChecks.length > 6 && equipCheckIdx == 6",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTd maintabTdgreen",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000358"
                                                          )
                                                        ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.mapObj
                                                              .taskDetail
                                                              .equipChecks
                                                              .length - 6
                                                          ) +
                                                          " " +
                                                          _vm._s(
                                                            _vm.$message(
                                                              "MSG0000135"
                                                            )
                                                          )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    _vm.mapObj.taskDetail
                                                      .equipChecks.length ===
                                                      0 &&
                                                    !_vm.isTaskDetailShow,
                                                  expression:
                                                    "mapObj.taskDetail.equipChecks.length === 0 && !isTaskDetailShow",
                                                },
                                              ],
                                              staticClass: "maintabTable",
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "maintabTd maintabTdgreen",
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(
                                                      _vm.$message("MSG0000136")
                                                    )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value: _vm.isTaskDetailShow,
                                                  expression:
                                                    "isTaskDetailShow",
                                                },
                                              ],
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "maintabTable maintabTableTop",
                                                },
                                                [
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "maintabTr maintabTd6 text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label("LBLEQUIP")
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "maintabTr maintabTd1 text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000377"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "maintabTr maintabTd1 text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000378"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "maintabTr maintabTd1 text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000352"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "maintabTr maintabTd1 text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000380"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "maintabTr maintabTd1 text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000379"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "maintabTr maintabTd1 text-center",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$label(
                                                            "LBL0000350"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "maintablebody maintablebody2",
                                                },
                                                [
                                                  _vm._l(
                                                    _vm.mapObj.taskDetail
                                                      .equipChecks,
                                                    function (
                                                      assess,
                                                      assessIdx
                                                    ) {
                                                      return _c(
                                                        "div",
                                                        {
                                                          directives: [
                                                            {
                                                              name: "show",
                                                              rawName: "v-show",
                                                              value:
                                                                _vm.mapObj
                                                                  .taskDetail
                                                                  .equipChecks
                                                                  .length > 0,
                                                              expression:
                                                                "mapObj.taskDetail.equipChecks.length > 0",
                                                            },
                                                          ],
                                                          key: assessIdx,
                                                          staticClass:
                                                            "maintabTable",
                                                        },
                                                        [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTd6 link-line padding-left10",
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.linkEquipCheckClick(
                                                                      {
                                                                        id: assess.id,
                                                                      }
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  assess.name
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTd1 text-center",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.setTagName(
                                                                    assess.checkStatusCd
                                                                  )
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTd1 text-center",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  assess.checkScheduleDate
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTd1 text-center",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  assess.checkScheduleUserName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTd1 text-center",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  assess.checkDate
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTd1 text-center",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  assess.checkResultName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "maintabTd maintabTd1 text-center",
                                                            },
                                                            [
                                                              _vm._v(
                                                                _vm._s(
                                                                  assess.equipmentCheckKindName
                                                                )
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    }
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      directives: [
                                                        {
                                                          name: "show",
                                                          rawName: "v-show",
                                                          value:
                                                            _vm.mapObj
                                                              .taskDetail
                                                              .equipChecks
                                                              .length === 0,
                                                          expression:
                                                            "mapObj.taskDetail.equipChecks.length === 0",
                                                        },
                                                      ],
                                                      staticClass:
                                                        "maintabTable",
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "maintabTd maintabTdgreen",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              _vm.$message(
                                                                "MSG0000136"
                                                              )
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                2
                                              ),
                                            ]
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]),
                          ],
                          2
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12" },
          [_c(_vm.impr.component, { tag: "component" })],
          1
        ),
      ]),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
      _c("c-notice-popup"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }